@import 'vars';
@import 'mixins';

$font-size-base: 14px;
$total-height: 85vh; // It is technically 100vh minus the header

/* General Styles */
body,
html {
  font-weight: 300;
  margin: 0;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  transition: opacity 400ms;
  display: block;
  position: static;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  @apply antialiased;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  overflow: hidden !important;
}

*:focus {
  outline: none !important;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
}

header {
  position: fixed;
  z-index: 10;
  width: 100%;
  right: 0;
  letter-spacing: 1px;

  nav {
    float: right;
  }
}

p {
  word-wrap: break-word;
}

a {
  font-weight: 300;
  cursor: pointer;
  &:hover,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
}

b,
strong {
  font-weight: 400;
}

select {
  cursor: pointer;
}

.btn {
  // font-family: var(--font-primary);
  &.btn-mixtape {
    padding-top: 0 !important;
    padding-bottom: 7px;
    padding-left: 15px;

    [class^='ss-icon-'],
    [class*=' ss-icon-'] {
      top: 6px;
      position: relative;
    }
  }

  &.btn-link {
    color: var(--secondary);

    &:focus {
      text-decoration: none;
      color: var(--secondary);
    }

    &.btn-image {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.main-content {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  z-index: 2;
}

/* labels */
.label-default {
  background-color: transparent;
  border: 1px solid #fff;
  @include border-radiuses(0);
}

/* ONE PAGE SECTIONS */
section {
  width: 100%;
  height: 100%;
}

section .section-content {
  background-attachment: fixed;
}

.back {
  transform: translate3d(-40%, 0, 0);
}

.front {
  transform: translate3d(61.6%, 0, 0);
}

.side {
  transform: translate3d(-338%, 0, 0);
}

/* Sections */
.section-content {
  /* General Background*/
  section & {
    // background: url('/assets/images/backgrounds/bg_sostereo.jpg') no-repeat 0 0 transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .classic-bg & {
    background: url('/assets/images/backgrounds/bg_sostereo.jpg') no-repeat 0 0 transparent;
  }

  #playlists & {
    background: url('/assets/images/backgrounds/about_us.jpg') no-repeat 0 0 transparent;
  }

  #contactUs & {
    background: url('/assets/images/backgrounds/bg_sostereo.jpg') no-repeat 0 0 transparent;
  }
}

.page-content {
  .section-content {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover !important;
    height: 100%;
    width: 100%;
    z-index: 0;

    &:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background: white;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0);
      // background: rgba(0,0,0,0.21);
      // background: -moz-linear-gradient(top, rgba(0,0,0,0.21) 0%, rgba(255,255,255,0.18) 32%, rgba(255,255,255,0.11) 100%);
      // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.21)), color-stop(32%, rgba(255,255,255,0.18)), color-stop(100%, rgba(255,255,255,0.11)));
      // background: -webkit-linear-gradient(top, rgba(0,0,0,0.21) 0%, rgba(255,255,255,0.18) 32%, rgba(255,255,255,0.11) 100%);
      // background: -o-linear-gradient(top, rgba(0,0,0,0.21) 0%, rgba(255,255,255,0.18) 32%, rgba(255,255,255,0.11) 100%);
      // background: -ms-linear-gradient(top, rgba(0,0,0,0.21) 0%, rgba(255,255,255,0.18) 32%, rgba(255,255,255,0.11) 100%);
      // background: linear-gradient(to bottom, rgba(0,0,0,0.21) 0%, rgba(255,255,255,0.18) 32%, rgba(255,255,255,0.11) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=0);
      z-index: 1;
    }

    .main-content {
      .container {
        top: 15%;
        position: relative;
      }

      .container-wrapper {
        // background: #000;
        // background: rgba(0, 0, 0, 0.4);
        top: 110px;
        position: relative;
        padding: 20px;
      }

      .container-without-bg {
        //top: 80px;
        position: relative;
        padding: 20px;
        overflow: auto;

        .container {
          //padding-bottom: 80px;
          top: 0;
        }
      }
    }
  }

  .multiply-gradiant:before {
    mix-blend-mode: multiply;
  }

  .left-gradient:before {
    background-blend-mode: multiply;
    background-image: linear-gradient(
      244deg,
      rgba(0, 0, 0, 0),
      // Starting point (transparent)
      rgba(0, 0, 0, 0.5) 50%,
      // Darker gray-blue color at 50%
      rgba(0, 0, 0, 0.85) 80%,
      // Darker blue color at 80%
      rgb(0, 0, 0) 95% // Solid dark blue at 95%
    );
    background-image: -moz-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.85) 80%,
      rgb(0, 0, 0) 95%
    );
    background-image: -webkit-gradient(
      linear,
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.85) 80%,
      rgb(0, 0, 0) 95%
    );
    background-image: -webkit-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.85) 80%,
      rgb(0, 0, 0) 95%
    );
    background-image: -o-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.85) 80%,
      rgb(0, 0, 0) 95%
    );
    background-image: -ms-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.85) 80%,
      rgb(0, 0, 0) 95%
    );
  }

  .top-gradient:before {
    background-blend-mode: multiply;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
    background-image: -moz-linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
    background-image: -webkit-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
    background-image: -webkit-linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
    background-image: -o-linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
    background-image: -ms-linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.67) 30%,
      rgba(0, 0, 0, 0.38) 60%,
      rgba(0, 0, 0, 0)
    );
  }
}

/* Modals */
.modal {
  .modal-body,
  .modal-footer {
    color: #6b6a6a;
    padding: 20px 20px;

    .ss-icon-Check {
      color: #06b8bd;
      font-size: 60px;
      display: block;
    }

    .ss-icon-fail {
      color: var(--error);
      font-size: 60px;
      display: block;
    }

    h4 {
      font-size: 25px;
      font-family: var(--font-primary);
      text-shadow: none;
      text-transform: uppercase;
      font-weight: 300;
      color: var(--secondary);
    }

    h4.success,
    p.success,
    h4.error,
    p.error {
      text-align: center;
    }

    p {
      text-shadow: none;
      padding: 0;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .modal-dialog {
    @media screen and (min-width: 768px) {
      &.modal-sm {
        width: 400px;
      }
    }
  }

  .modal-footer {
    text-align: center;
  }

  .nicescroll-rails {
    > div {
      background-color: rgba(0, 0, 0, 0.7) !important;
      border: rgba(0, 0, 0, 0.7) !important;
    }
  }
}

.close-absolute {
  position: absolute;
  right: 15px;
}

#player {
  .progress {
    width: 100%;
    max-height: 2px;
    margin: 0px auto 0;
    background: transparent;
  }
}

/* TABS */
.panel {
  background-color: transparent;
}

.nav-tabs {
  &.nav-justified {
    > li {
      background: #000;
      background: rgba(10, 35, 58, 0.8);
      color: #969696;

      a {
        color: #969696;
        border: 0;
        cursor: pointer;
        @include border-radiuses(0);

        &:hover,
        &:focus {
          //border-color: #eeeeee #eeeeee #dddddd;
          background-color: rgba(15, 217, 247, 0.3);
          //color: #0fd9f7;
          color: #fff;
          border: 0;
        }
      }

      &.active a {
        border: 0;
        background: rgba(15, 217, 247, 0.5);
        color: #fff;
        cursor: not-allowed;

        &:hover,
        &:focus {
          border: 0;
        }
      }
    }
  }
}

.tab-content {
  > .tab-pane {
    //max-height: 355px;
    overflow: auto;
    background: rgba(38, 62, 84, 0.5);
    padding: 10px;
    margin-top: 5px;
  }
}

/* TABLES */
.table {
  background: rgba(0, 0, 0, 0.5);

  > thead,
  > tbody,
  > tfoot {
    > tr {
      &.primary {
        background-color: var(--secondary);
      }

      > td,
      > th {
        vertical-align: inherit;

        &.primary {
          background-color: var(--secondary);
        }
      }
    }
  }

  &.table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background: rgba(38, 62, 84, 0.3);
  }
}

.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}

.download-spinner {
  font-size: 24px;
  float: left;
  margin-right: 10px;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

@media (max-width: 575px) and (orientation: portrait),
  (max-width: 991px) and (orientation: landscape) {
  .mobile-hidden {
    display: none !important;
  }
}

.word-break {
  word-break: break-all;
}

.capitalize {
  text-transform: capitalize;
}

.header-top-margin {
  overflow-x: hidden;
}

.logo {
  height: 100px;
  width: auto;
}

.logo-sm {
  width: 90px;
  height: auto;
}

@media (max-width: 991px) {
  .sm-centered-btn {
    display: block;
    margin: auto;
    margin-top: 30px !important;
    margin-bottom: 40px !important;
  }
}

#mc_embed_signup #mce-success-response {
  color: var(--primary-light) !important;
}

.left-margin-lg {
  padding-left: 80px;
}

.right-margin-lg {
  padding-right: 80px;
}

.top-margin-lg {
  padding-top: 80px;
}

.bottom-margin-lg {
  padding-bottom: 70px;
}

@media (max-width: 575px) {
  .left-margin-lg {
    padding-left: 40px;
  }

  .left-margin-lg {
    padding-right: 50px;
  }
}

.word-break {
  word-break: break-word !important;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.position-parent {
  display: flex;
}

.center-child {
  align-self: center;
}

.bottom-child {
  align-self: flex-end;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    border: 4px solid var(--secondary);
    opacity: 0;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  50% {
    top: 14px;
    left: 14px;
    width: 29px;
    height: 29px;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

#scroll-content {
  min-height: 100vh;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}

#main-box-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 44px;
  padding-right: 44px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  min-height: $total-height * 0.7;
  min-height: 486px;
  width: $box-width;

  h1,
  h3 {
    color: var(--primary-dark);
  }

  .title-section {
    margin-bottom: 35px;

    img {
      height: 80px;
      width: auto;
    }

    h1 {
      color: var(--contrast-light) !important;
      vertical-align: bottom;
      display: inline;
    }
  }

  .action-btns {
    margin-top: 60px;
    padding-bottom: 30px;
  }
}

.auto-height {
  padding-top: 50px;
  height: auto !important;
}

@media (max-width: 768px) {
  #main-box-section {
    position: unset;
    min-height: 100vh;
    width: auto;
  }
}

/* 1792x828px at 326ppi iPhone 11 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  #main-box-section {
    min-height: 90vh;
  }
}

@media (max-width: 596px) {
  .title-section {
    img {
      margin-right: 20px;
    }
  }

  #main-box-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.more-images {
  background-color: rgba(0, 0, 0, 0.68);
  border: 1px solid #fff;

  p {
    font-size: 80px;
    text-align: center;
    color: #fff;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.78);

    p {
      color: rgb(218, 218, 218);
    }
  }
}

.new-bkg-img {
  height: 120px;
  width: 200px;
  -webkit-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.61);
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.61);
}

.new-bkg-img-list {
  max-width: 100%;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}

.scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555555;
}

.scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: #555555;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #f5f5f5;
}

.scroll-bar-search::-webkit-scrollbar {
  height: 0px;
  background-color: transparent;
}

.scroll-bar-search::-webkit-scrollbar-track {
  width: 0px;
  background-color: transparent;
}

.scroll-bar-search::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.now-playing {
  font-size: 12px;
  font-family: var(--font-primary);
  margin-top: 0;
  margin-bottom: 0;
}

.font-italic {
  font-style: italic;
}

.color-success {
  color: var(--secondary);
}

.underline-none {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.word-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.resize-none {
  resize: none;
}

.error-bl {
  border-left: 2px solid var(--error);
}

.error-bb {
  border-bottom: 2px solid var(--error) !important;
}

.default-bb {
  border-bottom: 1px dashed #888888;
}

.yt-pending-tag {
  background-color: #c3c3c3 !important;

  span {
    color: #757575 !important;
  }
}

.fade-in-animation {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
 This is to remove the checkbox
 from the groupBy title
 for the angular2-multiselect
*/
angular2-multiselect {
  .dropdown-list.tagToBody {
    position: absolute;
    width: 100%;
    top: unset !important;
    left: unset !important;

    #searchIcon {
      top: 9px !important;
    }
  }

  .grp-title {
    pointer-events: none;

    ul {
      pointer-events: all;
    }
  }

  .grp-title > input[type='checkbox'] + label:before {
    display: none;
    content: none !important;
  }

  .grp-title > label {
    right: 15px;
    font-weight: 800 !important;
    color: #919191 !important;
  }

  .countplaceholder {
    margin-right: 0px !important;
  }

  .c-remove.clear-all {
    display: none;
  }

  .c-token {
    padding: 6px 22px 6px 8px !important;
    border-radius: 1rem !important;
  }

  .c-btn > span,
  .c-label {
    @apply text-[14px] lg:text-base;
  }

  .c-btn {
    border-radius: 1rem;
    border-color: black;
  }

  .list-area {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
  }

  .c-input,
  .c-input::placeholder {
    @apply text-[14px] lg:text-base;
  }

  .pure-checkbox input[type='checkbox'] + label:before {
    border-radius: 0px !important;
    color: var(--secondary);
    border: 1px solid var(--secondary);
  }

  .pure-checkbox input[type='checkbox']:checked + label:before {
    background: var(--secondary);
    border-radius: 0px;
  }

  .selected-list .c-list .c-token {
    background: var(--primary);
  }

  .selected-item {
    background: customLighten(--secondary, 45, 1);
  }
}

.textDecoration {
  text-decoration: underline !important;
}

.inviteMessage {
  strong {
    font-weight: 600 !important;
  }
}
