/* Buttons */

.btn {
  &.btn-mixtape {
    padding-top: 0!important;
    padding-bottom: 7px;
    padding-left: 15px;
    [class^="ss-icon-"], [class*=" ss-icon-"] {
      top: 6px;
      position: relative;
    }
  }
  &.btn-link {
    color: var(--secondary);
    &:focus {
      text-decoration: none;
      color: var(--secondary);
    }
    &.btn-image {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.btn-warning {
    &:hover, 
    &:focus, 
    &:active, 
    &.active, 
    .open > .dropdown-toggle {
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      border-color: #e0e0e0;
    }
  }
  &.btn-default {
    &:hover, 
    &:focus, 
    &:active, 
    &.active, 
    .open > .dropdown-toggle {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    opacity: 0.65;
    box-shadow: none;
    color: lighten(#000, 33.5%);
    opacity: 0.4;
    border-color: #ababac;
    background-color: #ababac;
    .btn-link {
      color: #B2B2B2;
      background-color: transparent;
      border-color: transparent;
    }
    .btn-default,
    .btn-success,
    .btn-warning {
      color: #D5D5D5;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-hover {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(68, 68, 68, 0.33);
  -moz-box-shadow: 5px 5px 5px 0px rgba(68, 68, 68, 0.33);
  box-shadow: 5px 5px 5px 0px rgba(68, 68, 68, 0.33);
}

.default-btn-dimension {
  border-radius: var(--border-radius);
  font-family: var(--font-primary);
  padding: 10px 20px 10px 20px;
}

.default-btn {
  padding: 10px 20px 10px 20px;
}

.lg-btn {
  letter-spacing: 1.9px;
  margin-top: 0px;
  padding: 14px 25px 14px 25px;
  margin-bottom: 0px;
}

.md-btn {
  letter-spacing: 1.9px;
  margin-top: 0px;
  padding: 8px 10px 8px 10px;
  font-size: 12px;

  .ss-icon-add {
    font-size: 14px;
    position: relative;
    top: 2px;
  }
}

.xs-btn {
  letter-spacing: 1.5px;
  padding: 2px 5px;
  font-size: 6px;
}

.sm-btn {
  padding: 4px 8px;
  font-size: 12px;
}

.clear-btn {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px 5px 0;
  border-color: #fff;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
    color: #fff;
  }
  &:focus {
    color: #fff;
  }
}

.default-color-btn {
  background-color: var(--default);
  border-color: var(--default);
  color: var(--primary);
  @extend .default-btn-dimension;

  &:hover, &:focus, &:active, &:active:focus {
    background-color: customDarken(--default, 5, 1);
    border-color: customDarken(--default, 5, 1);
    color: var(--primary);
  }
}

@mixin outline-btn-style($color, $bgColor, $cssOption) {
  @if $cssOption {
    background-color: var($bgColor);
  } @else {
    background-color: $bgColor;
  }
  border-color: var($color);
  color: var($color);
  @extend .default-btn-dimension;
  &:hover, &:focus, &:active {
    border-color: customDarken($color, 5, 1);
    color: customDarken($color, 5, 1);
  }
}

.outline-primary-btn {
  @include outline-btn-style(--primary, --default, true);
}

.outline-primary-dark-btn {
  @include outline-btn-style(--primary-dark, --default, true);
}

.outline-primary-2-btn {
  @include outline-btn-style(--primary-2, --default, true);
}

.outline-primary-light-btn {
  @include outline-btn-style(--primary-light, transparent, false);
}

.outline-secondary-btn {
  @include outline-btn-style(--secondary, --default, true);
}

.outline-secondary-dark-btn {
  @include outline-btn-style(--secondary-dark, --default, true);
}

.outline-secondary-light-btn {
  @include outline-btn-style(--secondary-light, transparent, false);
}

.outline-error-btn {
  @include outline-btn-style(--error, --default, true);
}



@mixin btn-style($bgColor, $color, $getDark, $cssOption) {
  color: $color;
  @if $cssOption {
    background-color: var($bgColor);
    border-color: var($bgColor);
  } @else {
    background-color: $bgColor;
    border-color: $bgColor;
  }
  @extend .default-btn-dimension;
  &:hover, &:focus, &:active, &:active:focus {
    color: $color;
    @if ($getDark) {
      @if $cssOption {
        background-color: customDarken($bgColor, 5, 1);
        border-color: customDarken($bgColor, 5, 1);
      } @else {
        background-color: darken($bgColor, 5);
        border-color: darken($bgColor, 5);
      }
    } @else {
      @if $cssOption {
        background-color: customLighten($bgColor, 10, 1);
        border-color: customLighten($bgColor, 10, 1);
      } @else {
        background-color: lighten($bgColor, 10);
        border-color: lighten($bgColor, 10);
      }
    }
  }
}

.primary-dark-btn {
  @include btn-style(--primary-dark, var(--default), false, true);
}

.primary-btn {
  @include btn-style(--primary, var(--default), true, true);
}

.primary-light-btn {
  @include btn-style(--primary-light, var(--primary-light-contrast, var(--primary-dark)), true, true);
}

.primary-2-btn {
  @include btn-style(--primary-dark, var(--default), true, true);
}

.secondary-btn {
  @include btn-style(--secondary, var(--default), true, true);
}

.secondary-dark-btn {
  @include btn-style(--secondary-dark, var(--default), true, true);
}

.secondary-light-btn {
  @include btn-style(--secondary-light, --primary-dark, true, true);
}

.error-btn {
  @include btn-style(--error, var(--default), true, true);
}

.cancel-btn {
  @include btn-style(#b5b5b5, #fff, true, false);
}

.white-btn {
  @include btn-style(#fff, var(--primary-dark), true, false);
}
