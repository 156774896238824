@import 'vars';

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

h1 {
  font-size: 45px;
  font-weight: 550;
}

.container-fluid,
.container-without-bg {
  h1 {
    font-size: 45px !important;
  }

  h2 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
  font-weight: 550;
  color: var(--primary);
}

h3 {
  font-size: 28px;
  font-weight: 500;
  // color: var(--primary);
}

p {
  font-family: var(--font-secondary);
  // font-weight: 400;
  font-size: 14px;
  // color: $default-text-color;
  font-weight: normal;
}

@media (max-width: 596px) {
  h1 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }
}

label {
  @apply font-secondary text-[13px];
  color: #919191;
}

.ng-invalid.ng-touched {
  border-color: var(--error);
}

.lds-ripple {
  div {
    border: 4px solid var(--secondary);
  }
}

.default {
  background-color: var(--default);
}

.primary-dark {
  background-color: var(--primary-dark);
}

.primary {
  background-color: var(--primary);
}

.primary-light {
  background-color: var(--primary-light);
}

.primary-2 {
  background-color: var(--primary-2);
}

.secondary {
  background-color: var(--secondary);
}

.secondary-dark {
  background-color: var(--secondary-dark);
}

.secondary-light {
  background-color: var(--secondary-light);
}

.error {
  background-color: var(--error);
}

.inactive-bg {
  background: var(--inactive);
}

.color-light {
  color: var(--default);
}

.color-primary {
  color: var(--primary);
}

.color-dark {
  color: var(--primary-dark);
}

.inactive-color {
  color: var(--inactive);
}

.primary-light-color {
  color: var(--primary-light);
}

.secondary-color {
  color: var(--secondary);
}

.error-color {
  color: var(--error);
}

@mixin link-style($color) {
  color: var($color);

  &:hover {
    color: customLighten($color, 15, 1);
  }
}

.primary-link {
  @include link-style(--primary);
}

.primary-dark-link {
  @include link-style(--primary-dark);
}

.primary-light-link {
  @include link-style(--primary-light);
}

.secondary-link {
  @include link-style(--secondary);
}

@mixin wrap-style($bgColor, $color) {
  background-color: $bgColor;
  color: $color;
}

.default-wrap {
  @include wrap-style(var(--primary-dark), var(--default));
}

.primary-dark-wrap {
  @include wrap-style(var(--primary-dark), var(--default));
}

.primary-wrap {
  @include wrap-style(var(--primary), var(--default));
}

.primary-light-wrap {
  @include wrap-style(var(--primary-light), var(--primary-light-contrast, var(--primary-dark)));
}

.primary-2-wrap {
  @include wrap-style(var(--primary-2), var(--default));
}

.secondary-wrap {
  @include wrap-style(var(--secondary), var(--default));
}

.secondary-dark-wrap {
  @include wrap-style(var(--secondary-dark), var(--default));
}

.secondary-light-wrap {
  @include wrap-style(var(--secondary-light), var(--primary-dark));
}

.error-wrap {
  @include wrap-style(var(--error), var(--default));
}

.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-v-flex {
  display: flex;
  align-items: center;
}

.flip-img-h {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
}

.display-i {
  display: inline;
}

.display-i-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.no-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

modal-container.fade.show {
  opacity: 1;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0px;
  left: -1.5px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;

  @media (max-width: 575px) {
    top: 0px;
    left: 0px;
  }
}

.high-light-elm > em {
  @apply text-primary font-semibold;
}

.pagination {
  li > a {
    color: var(--secondary);

    &:hover {
      color: customDarken(--secondary, 5, 1);
    }
  }

  .active a {
    z-index: 3;
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: default;

    &:hover,
    &:active,
    &:focus {
      background-color: customDarken(--secondary, 5, 1);
      border-color: customDarken(--secondary, 5, 1);
    }
  }
}

.border-radius {
  border-radius: var(--border-radius);
}

.dropdown-menu {
  background-color: var(--default);
  border-radius: var(--border-radius);
  border: 1px solid var(--primary);

  li span strong {
    color: var(--secondary);
    font-weight: 400;
  }

  li.active {
    background-color: customDarken(--secondary, 25, 0.7);

    a:focus {
      background-color: var(--secondary);
    }
  }
}

.progress-bar {
  background-color: var(--secondary);
}

.centered-text {
  text-align: center;
}

.non-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.back-link {
  color: var(--primary);
  text-decoration: none;

  .back-text {
    text-decoration: underline;
  }
}

.fw600 {
  font-weight: 600;
}
