@import 'vars';
@import 'material-icons/iconfont/material-icons.css';
@import 'common';
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'neue-montreal-regular';
    src: url('/assets/fonts/neue-montreal-regular.eot?gj5rlp'),
        url('/assets/fonts/neue-montreal-regular.woff?gj5rlp') format('woff'),
        url('/assets/fonts/neue-montreal-regular.ttf?gj5rlp') format('truetype'),
        url('/assets/fonts/neue-montreal-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'neue-montreal-medium';
    src: url('/assets/fonts/neue-montreal-medium.eot?gj5rlp'),
        url('/assets/fonts/neue-montreal-medium.woff?gj5rlp') format('woff'),
        url('/assets/fonts/neue-montreal-medium.ttf?gj5rlp') format('truetype'),
        url('/assets/fonts/neue-montreal-medium.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@layer base {
    html {
        font-size: 10px;

        @screen md {
            font-size: 14px;
        }

        @screen lg {
            font-size: 15px;
        }

        @screen xl {
            font-size: 16px;
        }
    }
}

.cdk-global-scrollblock {
    overflow-y: hidden;
}

div#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: white;
    display: none;

    #loader {
        z-index: 10001;
        -webkit-animation: rotation 4s infinite linear;
        margin: 35vh auto auto;
    }

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(359deg);
        }
    }
}

.player-list-icon {
    color: var(--secondary);

    &.fa-plus,
    &.fa-download {
        font-size: 12px;
    }

    &:hover {
        color: var(--primary-light);
    }
}

.modal-title {
    color: var(--primary-dark) !important;
}

#clear-bg .section-content {
    background-size: 100% 100% !important;
}

.sidenav-spacer {
    width: 70px;
    height: 100%;
    display: inline-block;
    float: left;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait),
    (max-width: 991px) and (orientation: landscape) {
        display: none;
    }
}

.sidenav-padding {
    padding-left: $sidenav-width;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait),
    (max-width: 991px) and (orientation: landscape) {
        padding-left: 0;
    }
}

.sidenav-width {
    width: calc(100% - $sidenav-width) !important;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait),
    (max-width: 991px) and (orientation: landscape) {
        width: 100%;
    }
}

.search-sidebar-padding {
    padding-left: $sidenav-width + $main-search-width;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        padding-left: 140px;
    }
}

.search-sidebar-width {
    width: calc(100% - ($sidenav-width + $main-search-width)) !important;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        width: 100%;
    }
}

.extended-sidebar-padding {
    padding-left: $sidenav-width + $main-search-width + $extended-search-width;
}

.extended-sidebar-width {
    width: calc(100% - ($sidenav-width + $main-search-width + $extended-search-width)) !important;
}

.search-playlist-padding {
    padding-left: $sidenav-width + $playlist-menu-width + $main-search-width;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        padding-left: 0;
    }
}

.search-playlist-width {
    width: calc(100% - ($sidenav-width + $playlist-menu-width + $main-search-width)) !important;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        width: 100%;
    }
}

.collapsed-search-playlist-padding {
    padding-left: $sidenav-width + $playlist-menu-width + $main-search-width - $collapsed-playlist-menu;
}

.collapsed-search-playlist-width {
    width: calc(100% - ($sidenav-width + $playlist-menu-width + $main-search-width - $collapsed-playlist-menu)) !important;
}

.search-playlist-padding-discover {
    padding-left: $playlist-menu-width + $main-search-width;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        padding-left: 0;
    }
}

.search-playlist-width-discover {
    width: calc(100% - ($playlist-menu-width + $main-search-width)) !important;

    @media (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
        width: 100%;
    }
}

.material-icons[data-icon]::before {
    content: attr(data-icon) !important
}

.material-icons-outlined[data-icon]::before {
    content: attr(data-icon)
}

.material-icons-sharp[data-icon]::before {
    content: attr(data-icon)
}

@import url('../../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import './content/content.scss';
@import './content/buttons.scss';
@import './content/mobile-app.scss';
@import './content/toastr.scss';