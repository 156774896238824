$box-width: 520px;
$sidenav-width: 70px;
$main-search-width: 138px;
$extended-search-width: 200px;
$playlist-menu-width:317px;
$collapsed-search-width: 54px;
$collapsed-playlist-menu: 43px + $collapsed-search-width;

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cover-size {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*
$color-variable-css: Color in hex
$value: Percentage of darkening or lightening (if none put 0)
$alpha: Transparency  (if none put 1)
*/
@function customLighten($color-variable-css, $value, $alpha) {
  $color-variable-h: var(#{$color-variable-css + "-h"});
  $color-variable-s: var(#{$color-variable-css + "-s"});
  $color-variable-l: calc(var(#{$color-variable-css + "-l"}) + #{$value+"%"});
  @return hsla($color-variable-h, $color-variable-s, $color-variable-l, $alpha);
}

@function customDarken($color-variable-css, $value, $alpha) {
  $color-variable-h: var(#{$color-variable-css + "-h"});
  $color-variable-s: var(#{$color-variable-css + "-s"});
  $color-variable-l: calc(var(#{$color-variable-css + "-l"}) - #{$value+"%"});
  @return hsla($color-variable-h, $color-variable-s, $color-variable-l, $alpha);
}