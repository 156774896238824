@import '../common_clasess';

// This is necessary to know the hsl of the hex colors by brand
:root {
  --primary: #000;
  --primary-10: #0000001A;
  --primary-20: #00000033;
  --primary-h: 0;
  --primary-s: 0%;
  --primary-l: 0%;
  --secondary-light: #FFE710;
  --secondary-light-h: 54;
  --secondary-light-s: 100%;
  --secondary-light-l: 53%;
  --default: #fff;
  --default-h: 0;
  --default-s: 0%;
  --default-l: 100%;
  --secondary: #14B3FC;
  --secondary-10: #14B3FC1A;
  --secondary-h: 199;
  --secondary-s: 97%;
  --secondary-l: 53%;
  --primary-light: #DEDEDE;
  --primary-light-10: #EFEFEF;
  --primary-light-h: 0;
  --primary-light-s: 0%;
  --primary-light-l: 87%;
  --primary-dark: #000;
  --primary-dark-h: 0;
  --primary-dark-s: 0%;
  --primary-dark-l: 0%;
  --error: #ff3e1d;
  --error-10: #ff3e1d1a;
  --error-h: 0;
  --error-s: 73%;
  --error-l: 41%;
  --dark-base: #868E8F;  
  --dark-base-10: #798081;

  --contrast-light: #DEDEDE;
  --contrast-light-h: 0;
  --contrast-light-s: 0%;
  --contrast-light-l: 87%;

  --fallback-search-bg: #345169;
  --fallback-search-bg-h: 207;
  --fallback-search-bg-s: 34%;
  --fallback-search-bg-l: 31%;
  --inactive: #ababab;
  --inactive-h: 0;
  --inactive-s: 0%;
  --inactive-l: 67%;
  --landing-bg: '/assets/images/backgrounds/landing.png';
  --search-bg: '/assets/images/backgrounds/geometric-bg-dark.png';
  --geometric-section-bg: '/assets/images/backgrounds/geometric-section-bg.jpg';
  --player-bg: '/assets/images/backgrounds/geometric-bg-dark-section.png';
  --fallback-search-bg: #345169;
  --inactive: #b2b2b2;
  --light-inactive: #e5e5e5;
  --border-radius: 16px;
  --font-primary: 'neue-montreal-medium', sans-serif;
  --font-secondary: 'neue-montreal-regular', sans-serif;
  --white-svg: invert(99%) sepia(21%) saturate(0%) hue-rotate(271deg) brightness(112%) contrast(100%);
  --black-svg: invert(0%) sepia(8%) saturate(7500%) hue-rotate(32deg) brightness(94%) contrast(107%);
}