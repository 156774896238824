@import 'mixins';


@media only screen
  and (min-width: 769px) {
	.show-for-mobile, .show-for-mobile-flex {
		display:none !important;
	}
}


/* ----------- Mobile Phones ----------- */

/* Portrait and Landscape */


@media only screen
  and (max-width: 767px),
  (max-width: 991px) and (orientation: landscape){

  html, body{
    margin: 0px;
    padding:0px;
    width: 100%;
    overflow-x: hidden !important;
  }

  .container-without-bg {
    overflow-x: hidden !important;
  }

  .hidden-for-mobile, .show-for-mobile-flex {
    display: none !important;
  }

  .show-for-mobile {
    display: block !important;
  }

  .show-for-mobile-flex {
    display: flex !important;
  }

	.search-text {
		font-size: 1.5em;
		padding: 15px;
	}

	/*CSS for Search Bar*/

	.search-input-wrapper {
		position: static;
	}

	.items-wrap {
		max-height: 300px !important;
	}

	.header-search{
    position: static !important;
	}

	.tab-container-box {
    div {
      ul {
        li#search-filters{
          @include setTabProperty(100%, left);
        }
        li#search-filters-tab{
          @include setTabProperty(50%, left);
        }
      }
    }
	}

	.songlist-wrapper {
		.song-list {
			height: 275px;
		}
	}
}

@media (max-width: 991px) {
  .hidden-from-md {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-for-md {
    display: none;
  }
}

/* iPhone 6*/
@media only screen
  and (min-width: 375px)
  and (max-width: 670px) {
  header nav {
    text-align: center;
  }
  .songlist-all-wrapper{
    top: 65%;
	}

	.view-all-songs-btn {
		position: fixed;
		right:0px;
		bottom:100px;
	}

	.swipe-area {
		position: fixed;
		width:100%;
		bottom:200px;
	}
	.app-modal-window .modal-content {
	    width: 100%;
	}

}


/* iPhone 5*/
@media only screen
  and (min-width: 320px)
  and (max-width: 568px)
  and (orientation: portrait){

 .popover-inner{
 	max-height: 300px !important;
 }

 .songlist-all-wrapper .songs-wrapper{
 	max-height: 200px !important;
 }
 	.app-modal-window .modal-content {
	    width: 100%;
	}
  .mixtape-mb-scroll {
    overflow-y: scroll;
  }
}

@media only screen
  and (min-width: 320px)
  and (max-width: 369px)
  and (orientation: portrait){
    .songlist-all-wrapper {
     top: 50%;
     overflow: auto;
    }
    .mixtape-position-mb {    
      top: 360px;
    }
}

@media only screen
  and (min-width: 360px)
  and (max-width: 374px)
  and (orientation: portrait){
    .songlist-all-wrapper {
     top: 45%;
     overflow: auto;
    }
    .mixtape-position-mb {
      top: 340px;
    }
}

@media only screen
  and (min-width: 375px)
  and (max-width: 568px)
  and (orientation: portrait){
    .songlist-all-wrapper {
     top: 40%;
     overflow: auto;
    }
    .mixtape-position-mb {
      top: 340px;
    }
}

 @media all and (max-width: 660px) {
  header nav {
    text-align: center;
  }
}
