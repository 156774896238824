@import 'vars';

@mixin  border-radiuses($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius:    $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius:  $bottomleft;
  -webkit-border-top-left-radius:     $topleft;

  -moz-border-radius-topright:        $topright;
  -moz-border-radius-bottomright:     $bottomright;
  -moz-border-radius-bottomleft:      $bottomleft;
  -moz-border-radius-topleft:         $topleft;

  border-top-right-radius:            $topright;
  border-bottom-right-radius:         $bottomright;
  border-bottom-left-radius:          $bottomleft;
  border-top-left-radius:             $topleft;
 
}

@mixin input-error() {
  border-color: var(--error);
  color: var(--error);
  background-repeat: no-repeat;
  background-position: 98% 7px;
  background-size: 7%;
}

@mixin input-success() {
  border-color: var(--secondary);
  color: var(--secondary);
  background-image: url('/assets/images/ss-icons-svg/Check.svg');
  background-repeat: no-repeat;
  background-position: 98% 7px;
  background-size: 7%;
}

@mixin setTabProperty($width, $float) {
  width: $width;
  float: $float;
}
