.toast-success {
    background-color: var(--secondary) !important;
}

.toast-info {
    background-color: var(--primary-light) !important;
    color: var(--primary) !important;
    background-image: url('/assets/images/icons/warning-ico.svg') !important;
}

.toast-warning {
    background-color: var(--secondary-light) !important;
    color: var(--primary) !important;
    background-image: url('/assets/images/icons/warning-ico.svg') !important;
}

.toast-message {
    font-weight: 400 !important;
    font-family: var(--font-secondary) !important;
    letter-spacing: 1px !important;
}

.toast-title {
    font-family: var(--font-primary) !important;
    font-weight: 500 !important;
    letter-spacing: 1px !important;
}

.download-song-toastr,
.download-playlist-toastr {
    top: 12px;
    right: 12px;

    &>.toast-info {
        background-image: none !important;

        &:before {
            font-size: 8px;
            line-height: 9px;
            width: 25px;
            color: transparent;
            content: 'download song loader';
            background-image: url(/assets/images/disco-shapes/logo.png) !important;
            background-size: 25px;
            background-repeat: no-repeat;
            -webkit-animation: rotation 8s infinite linear;
            animation: rotation 2s infinite linear;
            display: inline-block;
            top: 20px;
            right: 37px;
            position: relative;
            margin-top: -12px;
        }

        div {
            position: relative;
            bottom: 8px;
        }
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}